:root {
  --color-white: #FFFFFF;
  --color-white--50: rgba(255, 255, 255, 0.5);
  --color-white--25: rgba(255, 255, 255, 0.25);
  --color-white--10: rgba(255, 255, 255, 0.1);
  --color-black: #000000;
  --color-black--50: rgba(0, 0, 0, 0.5);
  --color-black--70: rgba(0, 0, 0, 0.7);

  --color-grey-900: #F4F4F6;
  --color-grey-800: #E9EAEC;
  --color-grey-700: #CCCCCC;
  --color-grey-650: #999999;
  --color-grey-590: #595959;
  --color-grey-350: #353535;
  --color-grey-333: #333333;
  --color-grey-266--70: rgb(38, 38, 38, .7);
  --color-grey-250: #252525;
  --color-grey-150: #1F1F1F;

  --color-primary: rgb(0 39 44); // #E03F3F
  --color-primary--20: rgba(224, 63, 63, 0.2); //#18ab5a
  --color-primary--80: rgba(224, 63, 63, 0.80); //#18ab5a
  --color-background: #141414; //0a0e17 //#08ea8f // rgb(255 79 103)

  --color-text: #FFFFFF;

  //--color-background-gradient:  linear-gradient(167deg, rgba(109,109,244,1) 0%, rgba(109,109,244,1) 40%, rgba(2,228,190,1) 100%);
  //--color-background-gradient--inverted: linear-gradient(167deg, rgba(2,228,190,1) 0%, rgba(109,109,244,1) 60%, rgba(109,109,244,1) 100%);;
  //--color-background-gradient--radial: radial-gradient(circle, var(--color-primary) 0%, rgba(109,109,244,1) 60%,  var(--color-secondary) 100%);
  //--color-background-gradient--radial-background: radial-gradient(circle, var(--color-primary) 0%, var(--color-white) 100%);
}
