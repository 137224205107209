.b-text {
  font-size: 16px;
  color: var(--color-white);

  &--l{
    font-size: 24px;
  }
  &--xl{
    font-size: 32px;
  }
  &--logo {
    font-weight: 800;
    color: var(--color-white);
    text-decoration: none;
    font-size: 48px
  }
}
