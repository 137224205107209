.b-btn {
  padding: 16px 40px;
  height: 56px;
  display: inline-block;
  margin: 0;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  min-width: 150px;
  font-weight: 400;
  background-color: var(--color-white);
  border: 2px solid transparent;
  color: var(--color-primary);

  &--s {
    height: 40px;
    padding: 8px 32px;

  }
  &--auto{
    width: auto;
  }
}
