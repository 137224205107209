@import "../../assets/fonts/icons/variables";

.c-file-input {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 300px;
  background-color: var(--color-white--10);
  border-radius: 6px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__label-upload {
    color: var(--color-white);
    position: absolute;
    top: calc(50% + 50px);
    left: 50%;
    z-index: 2;
    line-height: 1;
    transform: translate(-50%, -50%);
    font-weight: 200;
    font-size: 14px;
  }

  &__icon {
    background-color: transparent;
    border: 0;
    height: 0;
    width: 0;
    padding: 0;

    &:before {
      content: $icon-star-full;
      font-family: icomoon;
      font-size: 40px;
      color: var(--color-white);
      border-radius: 50%;
      background-color: var(--color-primary);
      padding: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      z-index: 2;
      line-height: 1;

    }

    &--add:before {
      transform: translate(-50%, -50%);
    }
  }


}
