
// ******************************************************************************
//  # Quicksand
// ******************************************************************************

@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand-Light.ttf");
  font-weight: 300;
}


@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand-Regular.ttf");
  font-weight: 400;
}


@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand-SemiBold.ttf");
  font-weight: 600;
}


@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand-Bold.ttf");
  font-weight: 700;
}
