.g-primereact-rating {
  .p-rating-icon {
    &:before {
      content: $icon-star-empty;
      font-family: "icomoon"
    }
    &.pi-star-fill{
      &:before {
        content: $icon-star-full;
        font-family: "icomoon"
      }
    }
  }
}
