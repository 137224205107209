.g-primereact-input-switch {
  --local-width: 88px;
  padding: 16px;
  width: var(--local-width);
  height: 40px;
  background-color: var(--color-grey-700);
  transition: .5s ease-in-out background-color;

  border-radius: 40px;
  display: block;
  .p-inputswitch-slider {
    border: 2px solid var(--color-white);
    border-radius: 30px;

    &:before {
      content: $icon-star-full;
      font-family: icomoon;
      font-size: 32px;
      top: 50%;
      left: 5px;
      transition: .5s ease-in-out left;
      transform: translateY(-50%);

    }
  }

  &.p-inputswitch-checked {
    background-color: var(--color-primary);

    .p-inputswitch-slider:before {
      left: calc(100% - 37px);
    }
  }
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
