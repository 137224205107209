.c-item-card {
  cursor: pointer;

  &__container-img{
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    padding-bottom: 150%;
    box-sizing: border-box;
    background-color: var(--color-white--10);
  }
  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover
  }

  &__title {
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    margin: 0;
    font-size: 18px;
    color: var(--color-white);

  }

  &__position{
    position: absolute;
    left: 10px;
    top: 10px;
    font-weight: 700;
    background-color: var(--color-primary);
    z-index: 1;
    color: var(--color-white);
    border-radius: 6px;
    padding: 8px;}

}
