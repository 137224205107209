.b-input {
  border: 0;
  background-color: var(--color-white);
  border-radius: 6px;
  position: relative;
  display: block;
  padding: 16px;
  width: 100%;
  min-height: 56px;
  color: var(--color-primary);
  box-sizing: border-box;
  &--textarea{
    min-height: 200px;

  }
}
