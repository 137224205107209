@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?llwjia') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?llwjia') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?llwjia##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bin {
  &:before {
    content: $icon-bin; 
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty; 
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half; 
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}

