.c-list-card {
  cursor: pointer;

  &__container-img{
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    padding-bottom: 150%;
    box-sizing: border-box;
  }
  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover
  }

  &__title {
    padding:  10px 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    margin: 0;
    font-size: 18px;
    color: var(--color-white);

  }

}
