$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-bin: "\e9ac";
$icon-star-empty: "\e9d7";
$icon-star-half: "\e9d8";
$icon-star-full: "\e9d9";
$icon-arrow-left: "\ea40";
$icon-search: "\e986";

